export function getCookie(name: string, json = false) {
  if (!name || !document.cookie) {
    return undefined;
  }

  let matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + '=([^;]*)'
    )
  );

  if (matches) {
    let res = decodeURIComponent(matches[1]);
    if (json) {
      try {
        return JSON.parse(res);
      } catch (e) {}
    }
    return res;
  }

  return undefined;
}

type DCookieOptions = {
  path?: string;
  domain?: string;
  expires?: Date | string;
  'max-age'?: number;
  secure?: boolean;
  samesite?: 'strict' | 'lax';
};

export function setCookie(
  name: string,
  value: string | object,
  _options: DCookieOptions = { path: '/' }
) {
  if (!name) {
    return;
  }

  const options: DCookieOptions = _options || {};

  if (_options.expires instanceof Date) {
    options.expires = _options.expires.toUTCString();
  }

  if (value instanceof Object) {
    value = JSON.stringify(value);
  }

  let updatedCookie =
    encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;

    let optionValue = options[optionKey as keyof DCookieOptions];

    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  try {
    document.cookie = updatedCookie;
  } catch (error) {
    console.error(error);
  }
}

export function deleteCookie(name: string) {
  setCookie(name, '', {
    expires: new Date(),
    path: '/',
    domain: 'bidfox.ru',
    secure: true,
  });
}
